import EventDispatcher from "js-class.event-dispatcher";

export class Provider {
	constructor(HTMLElement) {
		this.type = "Native";
		this.HTMLElement = HTMLElement;
		this.events = new EventDispatcher(this);
		this.player = null;
		// private state
		this.__started = null;
		this.__progress = 0;
	}

	getEmbed() {
		if(this.HTMLElement?.settings?.embed?.id)
		{
			const embed = document.createElement('div');
			embed.setAttribute('part','embed');
			embed.setAttribute('class','embed');

			const video = document.createElement('video');
			video.setAttribute('part','video');
			video.setAttribute('class','video');

			const source = document.createElement('source');
			source.setAttribute('part','source');
			source.setAttribute('class','source');
			source.setAttribute('src',this.HTMLElement?.settings?.embed?.id);
			video.appendChild(source);

			video.addEventListener('timeupdate',this.progressObserver.bind(this));

			embed.appendChild(video);

			this.player = video;
			this.fire('ready')

			return embed;
		}
		else
		{
			return false;
		}
	}

	async progressObserver() {
		if(this.getDuration()==0)
			return 0;
		let p = 100 * await this.getCurrentTime() / await this.getDuration();
		if(p!==this.__progress)
		{
			this.__progress = p;
			this.fire('progressChange');
		}
	}

	async getRatio() {
		if(this.player)
		{
			let w = await this.player.videoWidth;
			let h = await this.player.videoHeight;
			return w/h;
		}
		return;
	}
	async getDuration() {
		if(this.player)
			return this.player.duration
		return 0;
	}

	async playing() {
		if(this.player)
			return !this.player.paused;
	}
	async play() {
		if(this.player)
			this.player.play();
	}
	async pause() {
		if(this.player)
			this.player.pause();
	}
	async getCurrentTime() {
		if(this.player)
			return this.player.currentTime;
	}
	async setCurrentTime(seconds) {
		if(this.player)
			this.player.currentTime = seconds;
	}
	async setVolume(volume) { // 0-100
		if(this.player)
		{
			this.player.volume = volume / 100
			this.player.muted = 0;
			setTimeout(() => this.fire('volumeChange'),10)
		}
	}
	async getVolume() { // 0-100
		if(this.player)
		{
			return this.player.muted ? 0 : this.player.volume * 100
		}
		return 0;
	}
	async mute() {
		if(this.player)
		{
			this.player.muted = 1;
			setTimeout(() => this.fire('volumeChange'),10)
		}
	}
	async unMute() {
		if(this.player)
		{
			this.player.muted = 0;
			setTimeout(() => this.fire('volumeChange'),10)
		}
	}

	on(eventName,callback) { this.events.registerEvent(eventName,callback); }

	off(eventName,callback) { this.events.unregisterEvent(eventName,callback); }

	fire(eventName,data) {
		this.events.fireEvent(eventName, data);
	}
}

export default Provider;