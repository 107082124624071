import EventDispatcher from "js-class.event-dispatcher";
import Player from '@vimeo/player';

export class Provider {
	constructor(HTMLElement) {
		this.type = "Vimeo";
		this.HTMLElement = HTMLElement;
		this.events = new EventDispatcher(this);
		this.player = null;
		// private state
		this.__volume = null;
		this.__started = null;
		this.__progress = 0;
	}

	getEmbed() {
		if(this.HTMLElement?.settings?.embed?.id && !this.HTMLElement.querySelector('.embed'))
		{
			this.__embed = document.createElement('div');
			this.__embed.setAttribute('part','embed');
			this.__embed.setAttribute('class','embed');

			setTimeout(() => this.buildPlayer(), 1);

			return this.__embed;
		}
		else
		{
			return false;
		}
	}
	async buildPlayer() {
		if(!this.HTMLElement.root.querySelector('.iframe'))
		{
			this.player = null;
			const iframe = document.createElement('iframe');
			iframe.setAttribute('part','iframe');
			iframe.setAttribute('class','iframe');
			iframe.setAttribute('type','text/html');
			iframe.setAttribute('allow','autoplay; fullscreen; picture-in-picture; encrypted-media; accelerometer; gyroscope');
			iframe.setAttribute('src',`https://player.vimeo.com/video/${this.HTMLElement?.settings?.embed?.id}?autoplay=1&muted=1&background=1&controls=0&byline=0&dnt=1`);

			this.__embed.appendChild(iframe);

			setTimeout(() => this.buildPlayer(), 100);
		}
		else if(!this.player)
		{
			this.player = new Player(this.HTMLElement.root.querySelector('.iframe'))
			this.player.on('loaded',() => {
				if(!this.__started)
				{
					this.__started = setTimeout(() => this.fire('ready'), 10000);
					this.progressObserver_Interval = setInterval(this.progressObserver.bind(this),100);
				}
			})
		}
	}
	async destroyPlayer()
	{
		if(this.player && typeof this.player.destroy === 'function')
		{
			this.player.destroy()
		}
	}

	async progressObserver() {
		try {
			if(await this.getDuration()==0)
				return 0;

			let p = 100 * await this.getCurrentTime() / await this.getDuration();
			if(p!==this.__progress)
			{
				if(this.__started && this.__started !== true)
				{
					clearTimeout(this.__started)
					this.__started = true;
					this.fire('ready');
				}
				this.__progress = p;
				this.fire('progressChange');
			}
		}
		catch(e) {
			console.error('hapi-player Vimeo provider progressObserver halted');
			clearInterval(this.progressObserver_Interval);
		}
	}

	async getRatio() {
		if(this.player)
		{
			let w = await this.player.getVideoWidth();
			let h = await this.player.getVideoHeight();
			return w/h;
		}
		return ;
	}

	async getDuration() {
		if(this.player && typeof this.player.getDuration === 'function')
			return await this.player.getDuration()
		return 0;
	}

	async playing() {
		if(this.player)
			return !(await this.player.getPaused());
	}
	async play() {
		if(this.player)
			this.player.play()
	}
	async pause() {
		if(this.player)
			this.player.pause()
	}
	async getCurrentTime() {
		if(this.player && typeof this.player.getCurrentTime === 'function')
			return await this.player.getCurrentTime()
	}
	async setCurrentTime(seconds) {
		if(this.player)
			this.player.setCurrentTime(seconds)
	}
	async setVolume(volume) { // 0-100
		if(this.player)
		{
			this.__volume = volume;
			this.player.setMuted(volume<=0);
			this.player.setVolume(volume/100)
			setTimeout(() => this.fire('volumeChange'),10)
		}
	}
	async getVolume() { // 0-100
		if(this.player)
			return 100 * (await this.player.getVolume())
		return 0;
	}
	async mute() {
		if(this.player)
		{
			this.__volume = await this.getVolume()
			this.setVolume(0)
		}
	}
	async unMute(dflt=100) {
		if(this.player)
		{
			this.setVolume(this.__volume > 0 || dflt)
		}
	}

	on(eventName,callback) { this.events.registerEvent(eventName,callback); }

	off(eventName,callback) { this.events.unregisterEvent(eventName,callback); }

	fire(eventName,data) {
		this.events.fireEvent(eventName, data);
	}
}

export default Provider;