import EventDispatcher from "js-class.event-dispatcher";

export class Provider {
	constructor(HTMLElement) {
		this.type = "Youtube";
		this.HTMLElement = HTMLElement;
		this.events = new EventDispatcher(this);
		this.player = null;
		// private state
		this.__started = null;
		this.__progress = 0;
	}

	getEmbed() {
		if(this.HTMLElement?.settings?.embed?.id)
		{
			const embed = document.createElement('div');
			embed.setAttribute('part','embed');
			embed.setAttribute('class','embed');

			const sdk = document.createElement('script');
			sdk.setAttribute('src','https://www.youtube.com/iframe_api');
			embed.appendChild(sdk);

			const iframe = document.createElement('iframe');
			iframe.setAttribute('part','iframe');
			iframe.setAttribute('class','iframe');
			iframe.setAttribute('type','text/html');
			iframe.setAttribute('allow','autoplay; fullscreen; picture-in-picture; encrypted-media; accelerometer; gyroscope');
			iframe.setAttribute('src',`https://www.youtube.com/embed/${this.HTMLElement?.settings?.embed?.id}?mute=1&autoplay=1&cc_load_policy=0&enablejsapi=1&iv_load_policy=3&modestbranding=1&showinfo=0&controls=0`);
			embed.appendChild(iframe);

			var youtubeApiWatcher = setInterval(() => {
				if(typeof YT !== 'undefined' && typeof YT?.Player === "function")
				{
					clearInterval(youtubeApiWatcher);
					this.player = new YT.Player(iframe);
					this.player.addEventListener('onReady',() => {
						this.fire('ready')
					});
					setInterval(this.progressObserver.bind(this),50);
				}
			},100)

			return embed;
		}
		else
		{
			return false;
		}
	}

	async destroyPlayer()
	{
		if(this.player && typeof this.player.destroy === 'function')
		{
			this.player.destroy()
		}
	}

	async progressObserver() {
		if(this.getDuration()==0)
			return 0;
		let p = 100 * await this.getCurrentTime() / await this.getDuration();
		if(isNaN(p))
			return 0;
		if(p!==this.__progress)
		{
			this.__progress = p;
			this.fire('progressChange');
		}
	}

	async getRatio() {
		return this.HTMLElement?.settings?.embed.ratio;
	}
	async getDuration() {
		if(this.player && typeof this.player.getDuration === 'function')
			return this.player.getDuration()
		return 0;
	}

	async playing() {
		if(this.player)
			return this.player.getPlayerState() == YT.PlayerState.PLAYING;
	}
	async play() {
		if(this.player)
			this.player.playVideo();
	}
	async pause() {
		if(this.player)
			this.player.pauseVideo();
	}
	async getCurrentTime() {
		if(this.player && typeof this.player.getCurrentTime === 'function')
			return await this.player.getCurrentTime();
	}
	async setCurrentTime(seconds) {
		if(this.player)
		{
			this.player.seekTo(seconds);
		}
	}
	async setVolume(volume) { // 0-100
		if(this.player)
		{
			this.player.setVolume(volume)
			this.player.unMute();
			setTimeout(() => this.fire('volumeChange'),10)
		}
	}
	async getVolume() { // 0-100
		if(this.player)
		{
			return this.player.isMuted() ? 0 : this.player.getVolume()
		}
		return 0;
	}
	async mute() {
		if(this.player)
		{
			this.player.mute();
			setTimeout(() => this.fire('volumeChange'),10)
		}
	}
	async unMute() {
		if(this.player)
		{
			this.player.unMute();
			setTimeout(() => this.fire('volumeChange'),10)
		}
	}

	on(eventName,callback) { this.events.registerEvent(eventName,callback); }

	off(eventName,callback) { this.events.unregisterEvent(eventName,callback); }

	fire(eventName,data) {
		this.events.fireEvent(eventName, data);
	}
}

export default Provider;